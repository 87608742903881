<template>
  <div class="detailbox">
    <div class="detail_left">
      <div class="flexCB"  style="margin-bottom:20px;">
        <p class="tite">预约信息</p>
        <div @click="goback"  class="go_back el-icon-arrow-left">返回</div>
      </div>
      <div class="flexCB">
        <p class="deleft"><span class="tit_lf">预约编号</span> <span class="tit_lr">{{detailInfo.ireid}}</span></p>
        <p class="deleft"><span class="tit_lf">预约状态</span>
          <span  class="tit_lr" v-if="detailInfo.irestate == 2">待确认</span>
          <span  class="tit_lr" v-if="detailInfo.irestate == 3">已确认</span>
          <span  class="tit_lr" v-if="detailInfo.irestate == 4">已取消</span>
          <span  class="tit_lr" v-if="detailInfo.irestate == 5">已到店</span>
          <span  class="tit_lr" v-if="detailInfo.irestate == 6">服务完成</span>
        </p>
      </div>
      <div class="flexCB">
        <p class="deleft"><span class="tit_lf">发起时间</span> <span class="tit_lr">{{detailInfo.creatime}}</span></p>
        <p class="deleft"><span class="tit_lf">发起人</span> <span class="tit_lr">{{detailInfo.username}}</span></p>
      </div>
      <div class="flexCB">
        <p class="deleft"><span class="tit_lf">性别</span>
          <span v-if="detailInfo.usex ==1" class="tit_lr">男</span>
          <span v-if="detailInfo.usex ==2" class="tit_lr">女</span>
        </p>
        <p class="deleft"><span class="tit_lf">手机号码</span> <span class="tit_lr">{{detailInfo.uphone}}</span></p>
      </div>
      <div class="flexCB">
        <p class="deleft"><span class="tit_lf">美容师</span> <span class="tit_lr">{{detailInfo.sbecode}}</span></p>
        <p class="deleft"><span class="tit_lf">预约日期</span> <span class="tit_lr">{{detailInfo.iretime}} {{detailInfo.iredtime}}</span></p>
      </div>
      <p class="deleft"><span class="tit_lf">预约门店</span> <span class="tit_lr">{{detailInfo.shopname}}</span></p>
      <p class="deleft"><span class="tit_lf">备注</span> <span class="tit_lr">{{detailInfo.iremark}}</span></p>
    </div>
    <!-- 预约进度 -->
    <div class="detail_right">
      <p class="rf_tit" style="margin-bottom:20px;">预约进度</p>
      <el-steps direction="vertical" class="dia_step">
        <el-step class="diaStep_item" v-for="(item, index) in speedList" :key="index">
          <template slot="description">
            <p class="stepTime">{{ item.state_name }}</p>
            <p>{{ item.mark }}</p>
            <p>{{ item.creatime }}</p>
          </template>
        </el-step>
      </el-steps>
    </div>
  </div>
</template>

<script>
import { indentSpeed, indentDetail } from '@/api/reception'

export default {
  props: ['Info'],
  data () {
    return {
      speedList: [],
      detailInfo: ''
    }
  },
  created () {
    this.getSpeed()
    this.getDetail()
  },
  methods: {
    /* 预约详情 */
    getDetail () {
      indentDetail({ ireid: this.Info.ireid }).then(res => {
        if (res.status === 1) {
          this.detailInfo = res.data.data[0]
        }
      })
    },
    /* 流程 */
    getSpeed () {
      indentSpeed({ ireid: this.Info.ireid }).then(res => {
        if (res.status === 1) {
          this.speedList = res.data.data
        }
      })
    },
    goback () { /* 1-待处理列表，2-已完成预约-3消息列表 跳转过来 */
      if (this.Info.goType === '3') { // 返回消息列表
        this.$emit('detailBack')
        this.$router.go(-1)
      } else { // 返回预约列表
        this.$emit('detailBack')
      }
    }
  }
}
</script>

<style scoped>
.detailbox {
  width: calc(30% + 322px);
  min-width: 1000px;
  height: 100%;
  margin: 0 auto;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  font-size: 14px;
}
.detail_right{
  width: 322px;
  margin-left: 8px;
  position: relative;
  background: #fff;
  padding: 30px;
  box-sizing: border-box;
}
.detail_left{
  width: calc(100% - 322px);
  height: 100%;
  background: #fff;
  position: relative;
  padding: 30px;
  box-sizing: border-box;
  line-height: 40px;
}
.tite, .rf_tit {
  font-size: 18px;
  font-family: 'PingFang SC-Bold, PingFang SC';
  font-weight: bold;
  color: #333333;
}
.deleft {
  min-width: 40%;
}
.tit_lf {
  display: block;
  float: left;
  margin-right: 8px;
  width: 65px;
  font-size: 14px;
  font-family: 'PingFang SC-Regular, PingFang SC';
  font-weight: 400;
  color: #A5B7CC;
}
.tit_lr {
  font-size: 14px;
  font-family: 'PingFang SC-Regular, PingFang SC';
  font-weight: 400;
  color: #333333;
}
.go_back {
  cursor: pointer;
  color: #333333;
}
.stepTime {
  font-size: 14px;
  font-family: 'PingFang SC-Medium, PingFang SC';
  font-weight: 500;
  color: #333333;
}
.el-steps--vertical{
  height: auto;
}
.diaStep_item{
  font-size: 14px;
}
.dia_step >>> .el-step__icon.is-text{
  width: 14px;
  height: 14px;
  border: 1px solid #fff;
  background: #DDDDDD;
}
.dia_step >>> .el-step__icon-inner{
  display: none;
}
.dia_step >>> .el-step__line{
  width: 1px;
  left: 7px;
  background: #DDDDDD;
}
.dia_step >>> .el-step__title{padding: 0px;}
.dia_step >>> .el-step__main{margin-bottom: 15px;}
</style>
