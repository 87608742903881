<template>
  <div class="box100">
    <div v-if="type===1" class="box100 billList">
      <div class="reception_top">
        <Orders :Refresh="Refresh"></Orders>
      </div>
      <div class="reception_fot">
        <div class="flexCB">
          <div class="flexAC" style="margin: 16px 0px;">
            <el-input v-model="username"  placeholder="请输入姓名/手机号码" class="inputSearch"></el-input>
            <el-select v-model="irestate" filterable placeholder="状态" clearable  class="retSelect">
              <el-option
              v-for="(item,index) in stateList"
              :key="index"
              :label="item.label"
              :value="item.value">
              </el-option>
            </el-select>
            <el-date-picker
              v-model="time"
              clearable
              class="utf"
              type="daterange"
              range-separator="至"
              start-placeholder="开始时间"
              end-placeholder="结束时间"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd">
            </el-date-picker>
            <el-button class="btnSearch" @click="handleSearch">搜索</el-button>
            <el-button class="btnReset" @click="handleReset">重置</el-button>
          </div>
          <template v-if="roleBtn[3].power">
            <el-button  class="btncomplete" @click="handleComplete">已完成预约</el-button>
          </template>
        </div>
        <div class="card_list flexF scrollBox">
          <el-card class="cardBox" v-for="(item, index) in indetList" :key="index">
            <div class="flexCB">
              <p class="cardTitle">{{item.iredtime}}</p>
              <p style="color: #3C84EF;" v-if="item.irestate == 2">待确认</p>
              <p v-if="item.irestate == 3">已确认</p>
              <p v-if="item.irestate == 4">已取消</p>
              <p v-if="item.irestate == 5">已到店</p>
              <p v-if="item.irestate == 6">服务完成</p>
            </div>
            <div class="cardcont">
              <p>{{item.username}}/ {{item.uphone}}</p>
              <p>预约时间: {{item.iretime}}</p>
              <p>预约美容师: {{item.sbecode}}</p>
            </div>
            <div class="flexCE" style="margin-top: 10px;">
              <template v-if="roleBtn[0].power">
                <p class="card_tit" style="margin-right: 20px;" @click="handDetail(item)">详情</p>
              </template>
              <template v-if="roleBtn[1].power">
                <p class="card_tit" v-if="item.irestate !== 4 && item.irestate !== 6" style="margin-right: 20px;color: red;" @click="handCal(item)">取消预约</p>
              </template>
              <template v-if="roleBtn[1].power">
                <p class="card_tit" v-if="item.irestate == 3" style="margin-right: 20px;" @click="handStore(item)">已到店</p>
              </template>
              <template v-if="roleBtn[1].power">
                <p class="card_tit" v-if="item.irestate == 5" style="margin-right: 20px;" @click="handGoBill(item)">开单</p>
              </template>
              <template v-if="roleBtn[1].power">
                <p class="card_tit" style="margin-right: 20px;" v-if="item.irestate == 2" @click="handConfirm(item)">确认</p>
              </template>
              <template v-if="roleBtn[2].power">
                <p class="card_tit" v-if="item.sbeid === ''" @click="handDistr(item)">分配美容师</p>
              </template>
            </div>
          </el-card>
          <div class="notList" style="margin: 10% auto;" v-if="!indetList.length">
            <img src="../../assets/img/noData.png"/>
            <p>暂无数据</p>
          </div>
        </div>
        <el-pagination
          @current-change="pageChange"
          :current-page="page"
          :page-sizes="[10]"
          :page-size="size"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          class="settingPage">
        </el-pagination>
      </div>
    </div>

    <!-- 已完成订单 -->
    <div v-if="type===2"  class="whiteBg">
      <div class="flexCB">
        <div class="flexAC" style="margin: 16px 0px;">
          <el-input v-model="username"  placeholder="请输入姓名/手机号码" class="inputSearch"></el-input>
          <el-date-picker
            v-model="time"
            clearable
            class="utf"
            type="daterange"
            range-separator="至"
            start-placeholder="开始时间"
            end-placeholder="结束时间"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd">
          </el-date-picker>
          <el-button class="btnSearch" @click="handleSearch1">搜索</el-button>
          <el-button class="btnReset" @click="handleReset1">重置</el-button>
        </div>
        <el-button  class="btncomplete" @click="goback">返回上一级</el-button>
      </div>
      <div class="card_list flexF scrollBox">
        <el-card class="cardBox" v-for="(item, index) in indetList" :key="index">
          <div class="flexCB">
            <p class="cardTitle">{{item.iredtime}}</p>
            <p v-if="item.irestate == 3">已确认</p>
            <p v-if="item.irestate == 4">已取消</p>
            <p v-if="item.irestate == 5">已到店</p>
            <p v-if="item.irestate == 6">服务完成</p>
          </div>
          <div class="cardcont">
            <p>{{item.username}}/ {{item.uphone}}</p>
            <p>预约时间: {{item.iretime}}</p>
            <p>预约美容师: {{item.sbecode}}</p>
          </div>
          <div class="flexCE" style="margin-top: 10px;">
            <p class="card_tit" style="margin-right: 20px;" @click="handDel(item)">详情</p>
          </div>
        </el-card>
        <div class="notList" v-show="!indetList.length" style="margin: 15% auto;">
          <img src="../../assets/img/noData.png"/>
          <p>暂无数据</p>
        </div>
      </div>
      <el-pagination
        @current-change="pageChange1"
        :current-page="page1"
        :page-sizes="[10]"
        :page-size="size1"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total1"
        class="settingPage">
      </el-pagination>
    </div>

    <!-- 详情 -->
    <div v-if="type===3">
      <Recepdetail :Info="Info" @detailBack="detailBack"></Recepdetail>
    </div>

    <!-- 分配美容师 -->
    <el-dialog title="分配美容师" class="baetit" :visible.sync="beaShow" width="1200px" center  :close-on-click-modal="false">
      <Beaut @closeCancel="dialogClose" @selectConfirm="selectConfirm"></Beaut>
    </el-dialog>
  </div>
</template>

<script>
import Orders from '@/components/recepion/orders'
import Beaut from '@/components/recepion/beaut'
import Recepdetail from '@/components/recepion/recepdetail'
import { indentGet, indentOper } from '@/api/reception.js'
export default {
  name: 'receptionOrder',
  components: {
    Orders,
    Recepdetail,
    Beaut
  },
  props: ['roleBtn'],
  data () {
    return {
      page: 1,
      size: 10,
      total: 0,
      username: '',
      indetList: [],
      irestate: '',
      stateList: [{ label: '待确认', value: 2 }, { label: '已确认', value: 3 }, { label: '已取消', value: 4 }, { label: '已到店', value: 5 }],
      time: null,
      page1: 1,
      size1: 10,
      total1: 0,
      type: 1,
      backType: '1',
      Info: {},
      beaShow: false,
      ireid: '',
      Refresh: false, // 刷新
      megasid: null, // 从消息列表跳转来的预约id
    }
  },
  created () {
    this.megasid = this.$route.params.megasid
    if (this.megasid) { // 从消息列表跳转过来
      this.type = 3
      const obj = {
        ireid: this.megasid,
        goType: '3'
      }
      this.Info = obj
    } else {
      this.init()
    }
  },
  watch: {

  },
  methods: {
    init () {
      let staTime, endTime = ''
      if (this.time != null) {
        staTime = this.time[0]
        endTime = this.time[1]
      }
      const params = {
        username: this.username,
        irestime: staTime,
        ireetime: endTime,
        irestate: this.irestate,
        page_index: this.page,
        page_size: this.size
      }
      indentGet(params).then(res => {
        if (res.status === 1) {
          this.Refresh = !this.Refresh
          this.indetList = res.data.data
          this.total = res.data.recordcount
        } else {
          this.indetList = []
          this.total = 0
        }
      })
    },
    // 已完成刷新
    getInit () {
      let staTime, endTime = ''
      if (this.time != null) {
        staTime = this.time[0]
        endTime = this.time[1]
      }
      const params = {
        username: this.username,
        irestime: staTime,
        ireetime: endTime,
        irestate: 6,
        page_index: this.page,
        page_size: this.size
      }
      indentGet(params).then(res => {
        if (res.status === 1) {
          this.indetList = res.data.data
          this.total = res.data.recordcount
        } else {
          this.indetList = []
          this.total = 0
        }
      })
    },
    /* 点击开单 */
    handGoBill (item) {
      this.$router.push({
        name: 'receptionBill',
        params: {
          userid: item.uphone
        }
      })
    },
    /* 详情-未完成的 */
    handDetail (item) {
      this.type = 3
      this.backType = '1'
      const obj = {
        ireid: item.ireid,
        goType: '1'
      }
      this.Info = obj
    },
    /* 详情-已完成的 */
    handDel (item) {
      this.type = 3
      this.backType = '2'
      const obj = {
        ireid: item.ireid,
        goType: '2'
      }
      this.Info = obj
    },
    /* 详情返回 */
    detailBack () {
      if (this.backType === '1') {
        this.type = 1
      } else if (this.backType === '2') {
        this.type = 2
      }
      this.$emit('handMsgBack')
    },
    // 已完成订单
    handleComplete () {
      this.type = 2
      this.getInit()
    },
    // 返回上一级
    goback () {
      this.type = 1
      this.init()
    },
    /* 点击已到店 */
    handStore (item) {
      this.$confirm('请确认客户是否已到店并进行服务?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true
      }).then(() => {
        const params = {
          ireid: item.ireid,
          iretype: 5,
          sbeid: item.sbeid
        }
        indentOper(params).then(res => {
          if (res.status === 1) {
            this.$message.success(res.message)
            this.init()
          } else {
            this.$message.error(res.message)
          }
        })
      }).catch(() => {})
    },
    /* 点击取消预约 */
    handCal (item) {
      this.$confirm('是否取消预约订单?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true
      }).then(() => {
        const params = {
          ireid: item.ireid,
          iretype: 3,
          sbeid: item.sbeid
        }
        indentOper(params).then(res => {
          if (res.status === 1) {
            this.$message.success(res.message)
            this.init()
          } else {
            this.$message.error(res.message)
          }
        })
      }).catch(() => {})
    },
    /* 点击确认 */
    handConfirm (item) {
      this.$confirm('预约订单是否确认?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true
      }).then(() => {
        const params = {
          ireid: item.ireid,
          iretype: 1,
          sbeid: item.sbeid
        }
        indentOper(params).then(res => {
          if (res.status === 1) {
            this.$message.success(res.message)
            this.init()
          } else {
            this.$message.error(res.message)
          }
        })
      }).catch(() => {})
    },
    // 分配美容师 - 展示
    handDistr (item) {
      this.beaShow = true
      this.ireid = item.ireid
    },
    /* 分配美容师 - 关闭 */
    dialogClose () {
      this.beaShow = false
    },
    /* 分配美容师 - 提交 */
    selectConfirm (res) {
      this.beaShow = false
      const params = {
        ireid: this.ireid,
        iretype: 2,
        sbeid: res.sbeid
      }
      indentOper(params).then(res => {
        if (res.status === 1) {
          this.$message.success(res.message)
          this.init()
        } else {
          this.$message.error(res.message)
        }
      })
    },
    handleSearch () {
      this.init()
    },
    handleReset () {
      this.username = ''
      this.irestate = ''
      this.time = null
      this.init()
    },
    pageChange (page) {
      this.page = page
      this.init()
    },
    // 已完成列表
    handleSearch1 () {
      this.getInit()
    },
    handleReset1 () {
      this.username = ''
      this.time = null
      this.getInit()
    },
    pageChange1 (page) {
      this.page = page
      this.getInit()
    }
  }
}
</script>

<style scoped>
.reception_top{
  width: 100%;
  height: 160px;
}
.reception_fot{
  margin-top: 8px;
  width: 100%;
  height: calc(100% - 160px);
  background: #fff;
  padding: 16px;
  box-sizing: border-box;
}
.btncomplete {
  width: 110px;
  height: 32px;
  border-radius: 4px;
  border: 1px solid #3C84EF;
  color: #3C84EF;
  padding: 8px 20px;
  box-sizing: border-box;
}
.utf {
  height: 32px;
  padding: 0 12px;
  box-sizing: border-box;
}
.card_list{
  width: 100%;
  height: calc(100% - 130px);
}
.cardBox {
  width: 300px;
  background: #F7F9FB;
  margin-right: 16px;
  margin-bottom: 16px;
  font-size: 14px;
}
.cardcont {
  width: 260px;
  height: 152px;
  background: #FFFFFF;
  margin-top: 10px;
  color: #333333;
  font-size: 14px;
  padding-left: 10px;
  box-sizing: border-box;
}
.cardcont p{
  line-height: 30px;
}
.cardTitle{
  color: #A5B7CC;
  margin-bottom: 5px;
}
.card_tit {
  color: #3C84EF;
  cursor: pointer;
}
</style>
