<!-- 前台管理-【预约数】 -->
<template>
  <div class="reception_num">
    <div class="top_Type flexCB">
      <div class="rnTop_icon ico1">
        <img src="@/assets/img/yiyuyue.png" alt="">
        <p class="icon_tit tit1">已预约</p>
      </div>
      <div class="rnTop_num ico1 tit1">{{order.reserve_num}}</div>
    </div>
    <div class="top_Type flexCB">
      <div class="rnTop_icon ico2">
        <img src="@/assets/img/daiqueren.png" alt="">
        <p class="icon_tit tit2">待确认</p>
      </div>
      <div class="rnTop_num ico2 tit2">{{order.reserve_st_num}}</div>
    </div>
    <div class="top_Type flexCB">
      <div class="rnTop_icon ico3">
        <img src="@/assets/img/yiquxiao.png" alt="">
        <p class="icon_tit tit3">已取消</p>
      </div>
      <div class="rnTop_num ico3 tit3">{{order.reserve_no_num}}</div>
    </div>
    <div class="top_Type flexCB">
      <div class="rnTop_icon ico4">
        <img src="@/assets/img/yiwancheng.png" alt="">
        <p class="icon_tit tit4">已确认</p>
      </div>
      <div class="rnTop_num ico4 tit4">{{order.reserve_ok_num}}</div>
    </div>
  </div>
</template>

<script>
import { indentNum } from '@/api/reception.js'
export default {
  name: 'orders',
  props: ['Refresh'],
  data () {
    return {
      order: {}
    }
  },
  watch: {
    Refresh (val) {
      this.init()
    }
  },
  created () {
    this.init()
  },
  methods: {
    init () {
      indentNum().then(res => {
        if (res.status === 1) {
          this.order = res.data
        } else {
          this.order = {}
        }
      })
    }
  }
}
</script>

<style scoped>
.reception_num{
  width: 100%;
  height: 160px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.ico1{ background: rgba(60, 132, 239, 0.1) }
.ico2{ background: rgba(50, 201, 201, 0.1); }
.ico3{ background: rgba(244, 76, 142, 0.1); }
.ico4{ background: rgba(68, 181, 87, 0.1); }
.tit1 { color: #3C84EF; }
.tit2 { color: #32C9C9; }
.tit3 { color: #F44C8E; }
.tit4 { color: #44B557; }
</style>
